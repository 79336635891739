import { WhatsappWebhook } from './whatsapp-webhook.service'

const PATH = 'whatsapp/'

class WhatsappCancellationSubscriptionService extends WhatsappWebhook {
    cancellationSubscriptionLine (params = {}) {
        if (!this.provider) {
            throw new Error('Setting a provider is needed for this operation. i.e.: WhatsappCancellationSubscriptionService.setProvider(...)')
        }
        return this.postResult(PATH + this.provider + '/client-channel', params)
    }
}

export default new WhatsappCancellationSubscriptionService()
