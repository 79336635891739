import SubscriptionPlanService from '@/services/subscription-plan.service'
import WhatsappCancellationSubscriptionService from '@/services/whatsapp-cancellation-subscription.service'
import RoutesService from '@/services/whatsapp/routes.service'

export default {
    name: 'StopSubscription',
    components: {
    },

    props: {
        value: { type: Boolean, required: true },
        msisdn: { type: String, default: null },
        finalizedDate: { type: Function | Date, required: true },
        hasSubscription: { type: Boolean, required: true },
        userPlanId: { type: Number, required: true },
        userSubscriptionId: { type: Number, required: true },
        plan: { type: Object, required: true, default: () => { return {} } },
    },

    emits: [
        'stop-plan',
        'close-dialog',
    ],

    data () {
        return {
            dialogVisible: false,
        }
    },

    watch: {
        value (nv) {
            this.dialogVisible = nv
        },
        dialogVisible (nv) {
            this.$emit('input', this.dialogVisible)
        },
    },

    methods: {
        closeDialog () {
            this.$emit('close-dialog')
        },
        stopSubscriptionPlan () {
            const data = {
                userPlanId: this.userPlanId,
                userSubscriptionId: this.userSubscriptionId,
            }
            SubscriptionPlanService.stopSubscription(data)
                .then(result => {
                    if (result.success) {
                        switch (this.plan.planType) {
                            case 'whatsapp':
                                this.whatsappNeededActions()
                                break
                        }
                    }
                    this.$emit('stop-plan', this.userPlanId)
                })
            this.closeDialog()
        },
        whatsappNeededActions () {
            if (this.msisdn) {
                this.cancellationSubscription()
            }
        },
        cancellationSubscription () {
            const data = {
                type: 'CLIENT_CHANNEL',
                event: 'CANCELLATION_CHANNEL',
                msisdn: this.msisdn,
            }
            RoutesService.getCurrentUserRoutes().then((route) => {
                const selectedUserRoute = route.find(r => r.msisdn === this.msisdn)
                WhatsappCancellationSubscriptionService.setProvider(selectedUserRoute.providerName)
                WhatsappCancellationSubscriptionService.cancellationSubscriptionLine(data).then()
            })
        },
    },
}
